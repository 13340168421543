import React from 'react';
import PropTypes from 'prop-types';

import { Router } from '../../../common/routes';

const AddNewItemView = ({ route, params, label, t, customSearchFilter = false, onClick = null }) => (
  <div className="pull-right">
    <button
      className={customSearchFilter ? 'btn cafm-btn-small' : 'btn oms-btn new'}
      onClick={() => {
        typeof onClick === 'function' ? onClick() : Router.pushRoute(route, params);	      
      }}
    >
      {t(`${label}`)}
    </button>
  </div>
);

AddNewItemView.propTypes = {
  route: PropTypes.string.isRequired,
  params: PropTypes.shape().isRequired,
  label: PropTypes.string,
  t: PropTypes.func.isRequired,
};

AddNewItemView.defaultProps = {
  label: 'add new',
};

export default AddNewItemView;
